/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
// removing this import breaks TABS and I don't know what else, or why

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';

/* material-icons locally | Regular & Outlined */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(./assets/MaterialIcons-Regular.woff2) format('woff2');
}

@font-face {
    font-family: 'Material Icons Outlined';
    font-style: normal;
    font-weight: 400;
    src: url(./assets/MaterialIcons-Outlined.woff2) format('woff2');
  }

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;    
}

.material-icons-outlined {
    font-family: 'Material Icons Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$naked-typography: mat-typography-config(
    $font-family: 'Open Sans, Comfortaa, sans-serif',
    $display-1:     mat-typography-level(34px, 40px, 400),
    $headline:      mat-typography-level(24px, 32px, 400),
    $title:         mat-typography-level(20px, 32px, 500),
    $subheading-2:  mat-typography-level(16px, 28px, 400),
    $subheading-1:  mat-typography-level(15px, 24px, 400),
    $body-2:        mat-typography-level(13px, 24px, 500),
    $body-1:        mat-typography-level(13px, 20px, 400),
    $caption:       mat-typography-level(12px, 20px, 400),
    $button:        mat-typography-level(14px, 14px, 500),
    // Line-height must be unit-less fraction of the font-size.
    $input:         mat-typography-level(16px, 1.25, 400)
);

:root {
    --background-color: #252525;
    --accent-pink: #ff28a6; 
    --accent-purple: #c629d2;

    --pink-1: rgb(243 40 190);
    --yellow: rgb(249 247 51);
    --blue-1: rgb(53 227 255);
    --green-1: #28ed7c;
    --purple-1: #b360ff;
    --blue-2: #41b0ff;
}

// Max width for desktop
$maxWidth: 600px;

html, body { 
    height: 100%;
    background-color: #1e1e1e; // darker than --background-color for desktop contrast
    border-color: whitesmoke;
    // TODO: think about background color. Light, dark, both themes, colorful
    // background-image: linear-gradient(57deg, 
    //     var(--pink-1) 0%, var(--pink-1) 31%, 
    //     var(--yellow) 33%, var(--yellow) 54%, 
    //     var(--blue-1) 56%, var(--blue-1) 100%);
}

h2 {
    font-weight: 600;
}

textarea, input {
    caret-color: white !important;
}

#splash {
    background: linear-gradient(332deg, rgba(6,6,6,0.75) 0%, rgb(206 204 204 / 15%) 100%);
    height: 100%;
    width: 100%;
    .inner {
        background-image: url("./assets/naked-greece-v.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 150px;
        height: 100%;
        margin: auto;
    }
}

body {
    // overflow: hidden;
    margin: 0 auto;
    clear: both;
    font-size: mat-font-size($naked-typography, body-1);
    font-family: mat-font-family($naked-typography);
    line-height: mat-line-height($naked-typography, body-1);
}

a {
    color: white;
    text-decoration: none;
}

// ------------ SCROLLBAR ------------
/* width */
// ::-webkit-scrollbar {
    // width: 10px; // enable only on non-touch screens
// }
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f100; // transparent
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #666666; 
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
// ------------ /SCROLLBAR ------------


// .mat-toolbar {
//     box-shadow: 0px 0px 20px -1px black;
// }

// in-page header (single-post, profile, edit)
header {
    mat-toolbar.toolbar {
        position: fixed;
        z-index: 10;
        top: 0;
        width: 100%;
        // background: #1c1c1c // solid nav
        // blurred nav
        background: rgb(28 28 28 / 90%);
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
    }
    .mat-toolbar-row {
        padding: 0 7px;
    }
}

header.center-title {
    .mat-toolbar-row {
        .title {
        text-align: center;
        font-size: 16px;
        flex: 1 1;
        }
        .spacer {
        min-width: 40px;
        }
    }
}

header {
    
    img#logo {
        width: 170px;
        height: intrinsic; // Safari fallback
        height: 100%;
        padding-top: 4px;

        @media (min-width:600px) {
            width: 190px;
        }
    }

    &.left-title {
        mat-sidenav {
            min-width: 210px;
            width: fit-content;
            padding: 14px;
            mat-nav-list {
                button {
                    margin: 16px;
                }
            }
        }
        .nav {
            text-align: center;
            display: flex;
            align-items: center;
            // margin-left: 5px;
            z-index: 1;
            h3.user {
                font-size: 16px;
                font-weight: 600;
                margin-left: 8px;
            }
        }
        .spacer {
            flex: 1 1 auto;
        }
    }
}   

// Remove highlighted outlines on element selection
div, img {
    :focus {
        outline-width: 0px;
    }
}

// remove resize button from textareas
textarea {
    min-height: 40px;
    resize: none !important
}

// ------- Animated dots -------
.blink span {
    font-size: 20px;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.blink span:nth-child(2) {
    animation-delay: .2s;
}

.blink span:nth-child(3) {
    animation-delay: .4s;
}

@keyframes blink {
    0% {
        opacity: .2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: .2;
    }
}

// cropper
.no-padding .mat-dialog-container{
    padding: 0;
    background-color: rgba(0, 0, 0, 0.6);
    overflow: hidden;

    angular-cropper {
        position: absolute;
        left: 0;
        top: 5vh;
    }
    
    .cropper-view-box {
        outline: #da3fd6 solid 1px;
    }
    
    .cropper-point {
        background-color: #da3fd6;
        height: 10px;
        opacity: 1.7;
        width: 10px;
        border-radius: 10px;
    }

    .cropper-line {
        background-color: #da3fd6
    }
    
    @media (min-width: 768px) {
        .cropper-point.point-se {
            height: 10px;
            width: 10px;
        }
    }
}

// Material Design - Global (replacing ::ng-deeps)
.mat-tab-group>.mat-tab-header {
    // border-bottom-color: #d91717c4;
    border-bottom-color: #e5057fcc;
}
.mat-option.mat-selected:not(.mat-option-disabled) {
    color: var(--accent-pink) !important;
}
// .mat-tab-group.mat-primary .mat-ink-bar {
    // background-color: #711414 !important; // color="accent" is used instead
// }
.mat-checkbox-layout{
    white-space: normal !important;
}
.mat-checkbox-inner-container{
    margin-top: 4px !important;
}
// keep selected tab title brighter
.mat-tab-label-active{
    opacity: 1.0!important;
}
// Dialogs, dropdowns, menus background blur
mat-dialog-container.mat-dialog-container, div.mat-select-panel, div.mat-menu-panel {
    background: #242424cc;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}
mat-tab-header.sticky-tab-header {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: $maxWidth;
    padding-top: 60px;
    z-index: 2;
    background: var(--background-color);
}

// Input focused label and underline
.mat-focused .mat-form-field-label {
    /*change color of label*/
    color: darkgray !important;
   }
.mat-form-field-ripple {
    /*change color of underline when focused*/
    background-color: darkgray !important;
}

.mat-drawer-backdrop.mat-drawer-shown {
    background: #1c1c1cb3 !important;/* replace skyblue with any color */ 
}
mat-sidenav, mat-drawer {
    width: 70%;
}
.mat-drawer.mat-drawer-push {
    background-color: #42424240 !important;
}
button.download-app {
    margin: 30px auto;
    display: block;
}

// keep nav-bar tabs from overflowing horizontally
a.mat-tab-link {
    flex-basis: auto;
    flex-grow: 1;
    min-width: fit-content;
    opacity: 1;
    padding: 0 12px;
    border-radius: 5px 5px 0 0;
    -webkit-tap-highlight-color: transparent;
}

// three dot menu 
div.mat-menu-panel, mat-dialog-container.mat-dialog-container{
    min-width: 180px;
    max-width: 500px;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
}

.accept {
  color: #3fec3f;
}
.warn{
    color: #ff3f6f;
}
// accept button green
button.accept {
    color: var(--green-1);
    mat-icon.mat-icon {
        color: inherit;
    }
}
// warn button red
button.warn {
    color: #ff3f6f;
    mat-icon.mat-icon {
        color: inherit;
    }
}

.report-item {
    .mat-radio-label-content {
        white-space: normal;
    }
}

// Search Bar
#search-bar .mat-form-field-underline {
    display: none;
}
#search-bar > .mat-form-field-wrapper {
    margin-bottom: -1.25em;
}
// Search Tabs
#search-tabs.mat-tab-group>.mat-tab-header .mat-tab-label {
    flex-basis: auto;
    flex-grow: 1;
    min-width: fit-content;
    padding: 0 12px;
    border-radius: 5px 5px 0 0;
    -webkit-tap-highlight-color: transparent;
}
// Likes Comments Followers Dialog
.likes-comments-dialog .mat-dialog-container {
    padding: 0;
}

// snackbar notifications
snack-bar-container.snackbar {
    color: white;
    background: #982f43;
    margin-top: 70px !important;
}

// Report dialog / confirmation dialog
.mat-dialog-container {
    min-width: 300px;
}

.mat-dialog-content {
    .mat-horizontal-stepper-header-container {
        display: none;
    }
    .mat-horizontal-content-container {
        padding-bottom: 0;
        margin: 0 -24px;
    }
}

.mat-stepper-horizontal, .mat-stepper-vertical {
    background: #42424200 !important;
}

.mat-flat-button.mat-accent:not(.mat-button-disabled) {
    background: linear-gradient(45deg, #ea105a, #673ab7);
    background: linear-gradient(225deg, #008FFEff, #555FE2ff, #AA30C5ff, #FF00A9ff);
}

.mat-button, .mat-button {
    &.mat-gradient:not(.mat-button-disabled) {
        background: -webkit-linear-gradient(45deg,#ff00a2, #d959da, #649aff, #00aeff);
        background: -webkit-linear-gradient(50deg, #ff29c5, #d959da, #649aff, #04cffd);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: whitesmoke;
    }
}

.gradient-text {
    background: -webkit-linear-gradient(45deg, #ff29c5, #d959da, #649aff, #04cffd);
    background: linear-gradient(45deg, #ff29c5, #d959da, #649aff, #04cffd);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
// .gradient-icon {
//     background: webkit-linear-gradient(45deg, #ff00a2, #fd27ff, #558fff, #00dbff);
//     background: linear-gradient(45deg, #ff00a2, #fd27ff, #558fff, #00dbff);
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
// }

.accent {
    color: var(--accent-pink);
}

.flat-list {
    padding-left: 12px;
}

.icon-text-space {
    padding-left: 5px;
    font-weight: 400;
    font-size: small;
}

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$dark-primary-map: (
    50 : #e3e3e3,
    100 : #b8b8b8,
    200 : #898989,
    300 : #595959,
    400 : #363636,
    500 : #121212,
    600 : #101010,
    700 : #0d0d0d,
    800 : #0a0a0a,
    900 : #050505,
    A100 : #ff4e4e,
    A200 : #ff1b1b,
    A400 : #e70000,
    A700 : #ce0000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$accent-map: (
    50 : #fce9eb,
    100 : #f8c7ce,
    200 : #f4a2ad,
    300 : #f07c8c,
    400 : #ec6073,
    500 : #ff28a6 ,//#e9445a,
    600 : #e63e52,
    700 : #e33548,
    800 : #df2d3f,
    900 : #d91f2e,
    A100 : #ffffff,
    A200 : #ffdddf,
    A400 : #ffaab0,
    A700 : #ff9098,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$naked-background-color: var(--background-color);
$naked-primary: mat-palette($dark-primary-map);
$naked-accent: mat-palette($accent-map);
$naked-warn: mat-palette($mat-red, 500);


// Create the theme object (a Sass map containing all of the palettes).
// $naked-theme: mat-light-theme(
$naked-theme: mat-dark-theme(
    $naked-primary, 
    $naked-accent, 
    $naked-warn
);
    
// Insert custom background color
$background: map-get($naked-theme, background);
$background: map_merge($background, (background: $naked-background-color));
$naked-theme: map_merge($naked-theme, (background: $background));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($naked-theme);
@include angular-material-typography($naked-typography);



// ------------- Responsive CSS ---------------- //

body {
    max-width: $maxWidth;
    -webkit-tap-highlight-color: transparent;
}

header {
    max-width: $maxWidth;
    // mat-toolbar.toolbar {
    //     max-width: $maxWidth;
    // }
}

footer {
    max-width: $maxWidth;
}

.toolbar {
    max-width: $maxWidth;
    box-sizing: border-box;
}

div.mat-tab-link-container, .mat-form-field-flex {
    max-width: $maxWidth;
    margin: 0 auto;
}

.padbot {
    padding-bottom: 16px;
}

mat-form-field .mat-form-field {
    &-underline {
      position: static;
    }
    &-subscript-wrapper {
      position: static;
    }
}

.pointer {
    cursor: pointer;
}

mat-spinner {
    margin: auto;
    margin-top: 50px;
    &.inline {
        margin-top: 0;
    }
}

.padded-text {
    padding: 0 30px;
}

.spaces-around {
    padding: 0 5px;
}
 .inline-block {
    display: inline-block;
}

.couple-icon {
    margin-left: -2px;
    display: flex;
    mat-icon:nth-child(2) {
        margin: 0 -4px 0 -18px;
    }
}

section.main {
    min-width: 150px;
    max-width: 500px;
    margin: 0 auto;
    padding: 16px;
}

div.background-blur {
    opacity: 0.3;
    position: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    filter: blur(10px);
    width: 100%;
    max-width: $maxWidth;
    height: 100vh;
    z-index: -1;
}

.darkBackdrop {
    background: rgb(0 0 0 / 80%);;
}
.black-on-white > .mat-dialog-container {
    background: whitesmoke;
    color: #0c0c0c;
}

.underlined {
    // text-decoration: white dotted underline;
    text-decoration: white solid underline;
}

.self-mention {
    color: #ffc000;
    text-decoration-color: #ffc000;
    background-color: rgba(144,113,2,.1);
}

// angular-mentions dark
ul.mention-menu{
    // background-color: #4e4e4e !important;
    border-radius: 8px !important;
    background: #242424cc !important;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    .mention-item > div {
        color: whitesmoke !important;
        display: flex;
        align-items: center;
        margin-top: 5px;
        font-size: 14px;
    } 
    .mention-active > a {
        background-color: inherit !important;
    }
}

img.mention-avatar {
    border-radius: 50px;
    border: 1.5px solid;
    padding: 1px;
    object-fit: cover;
    width: 30px;
    height: 30px;
}

mention-list {
    left: 0 !important;
}

.fa-icon {
    padding-bottom: 8px;
}

.avatar {
    --b: 2px;
    padding: var(--b);
    // width: var(--w);
    height: fit-content;
    border-radius: 50%;
    aspect-ratio: 1;
    position: relative;
    z-index: 0;

    img {
        width: var(--w);
        height: var(--w);
        min-height: var(--w);
        display: flex;
        border-radius: 50%;
        padding: 1px;
        object-fit: cover;
        background: #ffffff00;
    }

    &.mat-list-icon {
        height: 56px!important;
        width: 56px!important;
        padding: 2px!important;
      }
}

.avatar:before {
    content:"";
    position:absolute;
    z-index:-1;
    inset: 0;
    padding: var(--b);
    border-radius: 50%;
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
            mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
    // For Safari ios
    width: 100%;
    height: 100%;
    left: 0 !important;
    top: 0 !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

// Genders [male, female, couple, trans]
.sex-m:before {
    // background: #007cff;
    // background: linear-gradient(45deg, #00d2ff, #3a7bd5);
    // background: linear-gradient(45deg, #00b4db, #0083b0);
    // background: linear-gradient(45deg, #1771ec, #00c4ff);
    background: linear-gradient(225deg, #00c4ff, #3e90ff, #1771ec);
}
.sex-f:before {
    // background: #ff00ac;
    // background: linear-gradient(45deg, #fe1ab8, #b32071);
    // background: linear-gradient(45deg, #ff00fa, #ff2579);
    // background: linear-gradient(45deg, #9c20aa, #fb3570);
    // background: linear-gradient(45deg, #eb11a5, #eb00c5, #e200ea);
    background: linear-gradient(45deg, #eb11a5, #cd00eb, #a200ea);
}

.sex-c:before {
    // background: linear-gradient(45deg, #007cff, #ff00ac);
    // background: linear-gradient(45deg, #9c20aa, #fb3570);
    // background: linear-gradient(45deg, #ff00cc, #3030ff);
    // background: linear-gradient(45deg, #00aeff, #8728ff, #ff00a2);
    background: linear-gradient(45deg,#ee38be, #a650ff,#3fa4ff);
    background: linear-gradient(45deg, #ff00a2, #d959da, #649aff, #00aeff);
}
.sex-tg:before, .sex-tgf:before, .sex-tgm:before {
    --t-blue: #4fa5c2;
    --t-pink: #f587ac;
    --t-white: #f9fbfc;
    background: linear-gradient(
      45deg,
      var(--t-blue) 0%,
      var(--t-blue) 20%,
      var(--t-pink) 20%,
      var(--t-pink) 40%,
      var(--t-white) 40%,
      var(--t-white) 60%,
      var(--t-pink) 60%,
      var(--t-pink) 80%,
      var(--t-blue) 80%,
      var(--t-blue) 100%
    );
}
.sex-nb:before {
    --nb-black: #222222;
    --nb-yellow: #f7e45a;
    --nb-white: #f9fbfc;
    --nb-purple: #675997;
    background: linear-gradient(
      45deg,
      var(--nb-yellow) 0%,
      var(--nb-yellow) 25%,
      var(--nb-white) 25%,
      var(--nb-white) 50%,
      var(--nb-purple) 50%,
      var(--nb-purple) 75%,
      var(--nb-black) 75%,
      var(--nb-black) 100%
    );
}
.sex-o:before {
    background: linear-gradient(
        45deg,
        #FE0000 16.66%, 
        #FD8C00 16.66%, 33.32%, 
        #FFE500 33.32%, 49.98%, 
        #119F0B 49.98%, 66.64%, 
        #0644B3 66.64%, 83.3%, 
        #C22EDC 83.3%
    );
}
.sex-lgbt:before {
    background: linear-gradient(
        180deg,
        #FE0000 16.66%, 
        #FD8C00 16.66%, 33.32%, 
        #FFE500 33.32%, 49.98%, 
        #119F0B 49.98%, 66.64%, 
        #0644B3 66.64%, 83.3%, 
        #C22EDC 83.3%
    );
}
// .hashtag-avatar, .location-avatar{
//     background: whitesmoke;
//     img {
//         box-shadow: inset 0px 0px 0 1px var(--background-color);
//     }
//     &:before {
//         background: whitesmoke;
//     }
// }
.hashtag-avatar, .location-avatar{
    // background: whitesmoke;
    &:before {
        background: whitesmoke;
    }
}
img.avatar-svg {
    background-color: whitesmoke;
    margin: 1px;
    box-sizing: border-box;
    // filter: invert(0.9);
}
.avatar-100 {
    --w: 100px;
    --b: 3px;
    img {
        padding: 2px;
        &.avatar-svg {
            margin: 2px;
        }
    }
}
.avatar-80 {
    --w: 80px;
    --b: 3px;
    img {
        padding: 2px;
        &.avatar-svg {
            margin: 2px;
        }
    }
}
.avatar-54 {
    --w: 54px;
}
.avatar-50 {
    --w: 50px;
}
.avatar-44 {
    --w: 44px;
}
.avatar-36 {
    --w: 36px;
}
.avatar-32 {
    --w: 32px;
}
.avatar-30 {
    --w: 30px;
}
.avatar-28 {
    --w: 28px;
    --b: 1px;
}
.avatar-26 {
    --w: 26px;
    --b: 1px;
}

.edit-avatar {
    width: 84px;
    height: 84px;
}

.single-avatar {
    width: 28px;
    height: 28px;
}

// background: linear-gradient(45deg, #ea105a, #673ab7);
// background: linear-gradient(45deg, #fe0d83, #681bf2);
// background: linear-gradient(45deg, #fe0d83, #00d4ff);
// background: linear-gradient(47deg, rgba(254,13,131,1) 0%, rgba(196,52,228,1) 42%, rgba(180,63,255,1) 60%, rgba(0,212,255,1) 100%);
// background: linear-gradient(45deg, #aa076b, #61045f);

.flex-apart {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.img-placeholder {
    background-color: #1b1b1b;
}

.mention-tag-count {
    padding-left: 20px;
}

.change-avatar {
    padding-left: 14px;
    line-height: 2;
}

.post-visibility {
    display: flex;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      mat-icon {
        font-size: 16px;
        height: 16px;
        width: 16px;
      }
      span {
        padding: 0 5px;
      }
    }
    mat-select {
      overflow: hidden;
    }
}

.animated-placeholder {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderGlow;
    animation-timing-function: linear;
    background: #1b1b1b;
    background: linear-gradient(to right, #1b1b1b 10%, #313131 18%, #1b1b1b 33%);
    background-size: 800px 104px;
    // height: 260px;
    position: relative;

    &.p20 {
        height: 20px;
    }
    &.p160 {
        height: 160px;
    }
    &.p260 {
        height: 260px;
    }
}
@keyframes placeHolderGlow{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.loved {
    color: #f51f4c;
}

.center {
    text-align: center;
}